import Vue from 'vue'
import Main from './Main.vue'
import store from './store'
import router from './router'

Vue.config.productionTip = false
export const bus = new Vue();

new Vue({
  render: h => h(Main),
  store,
  router,
}).$mount('#app')
