import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/components/pages/Home'
import Test from '@/components/pages/Test'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
      name: "Home",
    },
    {
      path: '/test',
      component: Test,
      name: "Test",
    }]
})
